<template>
  <div class="mt-2">
    <vx-table-simple
      :columns="columns"
      :items="items"
    >
      <template #title>
        Prizes
      </template>
    </vx-table-simple>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import VxCard from '@/components/VxCard'
import {
  VxTableSimple,
  VxCellButtons,
  VxCellImageWithTitle,
  VxCellLuckyRollPrizes
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { permissions, useCan } from '@/plugins/acl'
import { getMainPrice, moneyFormat } from '@/services/utils'
import { buildRoute, path } from '@/router'

export default {
  name: 'Prizes',

  components: {
    VxCard,
    VxTableSimple,
    VxPageTitleActions,
    VxButton
  },

  props: {
    prizes: {
      type: Array,
      default: () => ([])
    },
    prizeOnChangingItemMode: Object
  },

  setup (props, { emit }) {
    const items = ref(props.prizes)
    const hasPrizeInChangingItemMode = computed(() => !!props.prizeOnChangingItemMode)
    const canUpdate = !hasPrizeInChangingItemMode.value && useCan(permissions.luckyRollPrizeUpdate)

    watch(() => props.prizes, () => {
      items.value = props.prizes
    })

    const isActiveButton = ({ is_enabled, id }) => {
      const onClick = () => emit('onToggleActive', { id })

      return is_enabled
        ? [{ ...buttons.turnOff, label: 'Yes', disabled: true }]
        : [{ ...buttons.turnOn, label: 'No', can: canUpdate, onClick }]
    }

    const actionButtons = (item) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        onClick: () => emit('onSelect', item)
      }

      const changeSkinButton = {
        ...buttons.edit,
        can: canUpdate,
        label: 'Change skin',
        onClick: (prize) => emit('update:prizeOnChangingItemMode', prize)
      }

      const cancelChangeIteButton = {
        ...buttons.reject,
        label: 'Cancel changing',
        onClick: () => emit('update:prizeOnChangingItemMode', null)
      }

      const actionButtons = [editButton]

      return props.prizeOnChangingItemMode?.id === item.id
        ? [...actionButtons, cancelChangeIteButton]
        : [...actionButtons, changeSkinButton]
    }

    const columns = [
      {
        key: 'entity',
        label: 'Title',
        component: VxCellImageWithTitle,
        tdAttr: (value) => {
          const { file: { external_link } = {}, id, title } = value
          return {
            label: title,
            href: external_link,
            id: id,
            link: buildRoute(path.items, { query: { id } })
          }
        }
      },
      {
        key: 'description',
        label: 'Description'
      },
      {
        key: 'entity.prices',
        label: 'Price',
        formatter: (value, key, item) => {
          const { entity: { prices } } = item
          return moneyFormat(getMainPrice(prices))
        }
      },
      {
        key: 'main_price',
        label: 'Initial prize price',
        sortable: true,
        component: VxCellLuckyRollPrizes,
        formatter: (value, key, item) => {
          return [{
            ...item,
            hideLabel: true
          }]
        }
      },
      {
        key: 'is_enabled',
        label: 'Is Active',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: isActiveButton(item)
        })
      },
      {
        key: 'actions',
        label: 'Actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          vertical: true,
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      columns,
      items,
      filters: []
    }
  }
}
</script>
