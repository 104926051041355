<template>
  <div class="mt-2">
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
      suppress-route-handling
    >
      <template #title>
        Items
      </template>
    </vx-table>
  </div>
</template>

<script>
import {
  VxTable,
  VxCellButtons,
  VxCellImageWithTitle,
  VxCellMoney
} from '@/components/table'
import { ref, computed } from '@vue/composition-api'
import { passDataToResource, items, categories } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { buildMoney, getMainPrice } from '@/services/utils'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { permissions, useCan } from '@/plugins/acl'
import { filterTypes } from '@/services/table'

export default {
  name: 'Items',
  components: {
    VxTable,
    VxCellButtons,
    VxCellMoney,
    VxCellImageWithTitle
  },
  props: {
    prizes: {
      type: Array,
      default: () => ([])
    },
    prizeOnChangingItemMode: Object
  },
  setup (props, { emit }) {
    const tableRef = ref(null)

    const canCreatePrize = useCan(permissions.luckyRollPrizeCreate)

    const entityIdsOfPrizes = computed(() => props.prizes.map(({ entity: { id } }) => id))

    const actionButtons = ({ entity: { id } = {} } = {}) => {
      if (entityIdsOfPrizes.value.includes(id)) {
        return [{ ...buttons.active, label: 'Used', disabled: true }]
      }

      const addButton = {
        ...buttons.add,
        label: 'Create prize',
        can: canCreatePrize,
        onClick: (data) => emit('onSelect', data)
      }

      const editButton = {
        ...buttons.edit,
        label: 'Change to this item',
        onClick: (data) => emit('onChangeItemInPrize', data)
      }

      return props.prizeOnChangingItemMode ? [editButton] : [addButton]
    }

    const resource = passDataToResource(items.getAll, {
      requestParams: {
        params: {
          include: 'entity.file,category',
          filter: { type: 'skin' }
        }
      }
    })

    const columns = [
      {
        key: 'entity',
        label: 'Title',
        component: VxCellImageWithTitle,
        tdAttr: (value) => {
          const { file: { external_link }, id, market_hash_name } = value
          return {
            label: market_hash_name,
            href: external_link,
            id: id,
            link: buildRoute(path.items, { query: { id } })
          }
        }
      },
      {
        key: 'prices',
        component: VxCellMoney,
        tdAttr: (value) => ({
          money: buildMoney(Number(getMainPrice(value)))
        })
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const filters = [
      { key: 'title' },
      { key: 'id' },
      {
        key: 'variation',
        label: 'Skin',
        type: filterTypes.serverSelect,
        resource: categories.getVariations,
        resourceMapper: ({ variations }) => variations.sort()
      },
      {
        key: 'rarity',
        type: filterTypes.serverSelect,
        resource: categories.getBaseInfo,
        resourceMapper: ({ rarity }) => rarity,
        optionsLabel: 'full',
        reduce: ({ short }) => short
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      filters
    }
  }
}
</script>
