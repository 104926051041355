<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.title"
      :rules="rules.title"
      name="title"
      label="Title"
    />

    <vx-input
      v-model="formValue.min_bet_price"
      :rules="rules.min_bet_price"
      name="min_bet_price"
      label="Min bet price (in $)"
    />

    <vx-input
      v-model="formValue.max_bet_price"
      :rules="rules.max_bet_price"
      name="max_bet_price"
      label="Max bet price (in $)"
    />

    <vx-select
      v-model="formValue.roll_values"
      :rules="rules.roll_values"
      :options="[]"
      name="roll_values"
      label="Roll values"
      multiple
      deselect-from-dropdown
      taggable
    />

    <vx-select
      v-model="formValue.activity_type"
      :rules="rules.activity_type"
      :options="activityTypes"
      name="activity_type"
      label="Activity type"
    />

    <vx-checkbox
      v-model="formValue.is_enabled"
      name="is_enabled"
      label="Enabled"
    />

    <vx-button
      :loading="loading"
      :disabled="!canSubmit"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { VxForm, VxInput, VxSelect, VxCheckbox } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { luckyRolls, passDataToResource, useResource } from '@/services/resources'
import { apiDefaultValidators } from '@/services/form/validationService'
import { setValuesToForm } from '@/services/form'
import { cloneDeep, pick } from 'lodash'
import { formatToCents, formatToDecimal } from '@/services/utils'
import router, { buildRoute, path } from '@/router'
import { activityTypesEnum } from '@/services/resources/domainsConsts'
import { getDifferingFields } from '@/services/form/formService'

export default {
  name: 'LuckyRollEditor',
  components: {
    VxSelect,
    VxForm,
    VxInput,
    VxCheckbox,
    VxButton
  },
  props: {
    luckyRoll: {
      type: Object,
      default: null
    },
    isEdit: Boolean,
    isPrizesEmpty: Boolean
  },
  setup (props) {
    const activityTypes = Object.values(activityTypesEnum)

    const canSubmit = computed(() => {
      if (props.isEdit && !props.isPrizesEmpty) {
        const { can: canUpdate } = useResource(luckyRolls.update)
        return canUpdate
      }

      const { can: canCreate } = useResource(luckyRolls.create)
      return canCreate && isEnabled
    })

    const isEnabled = computed(() => formValue.is_enabled)

    const formValue = reactive({
      title: '',
      min_bet_price: null,
      max_bet_price: null,
      roll_values: [],
      activity_type: activityTypesEnum.all,
      is_enabled: false
    })

    const initialValue = ref(null)

    const rulesConsts = {
      min_value: 0,
      max_value: 42949672.95
    }

    const rules = {
      title: {
        required: true,
        max: apiDefaultValidators.maxTinyTextLength
      },
      min_bet_price: {
        required: true,
        decimal: 2,
        min_value: rulesConsts.min_value,
        max_value: rulesConsts.max_value
      },
      max_bet_price: {
        required: true,
        decimal: 2,
        between: [formValue.min_bet_price, rulesConsts.max_value]
      },
      roll_values: {
        required: true,
        lucky_rolls_roll_values: true
      },
      activity_type: {
        required: true
      }
    }

    const backToFrontMapper = (luckyRoll) => {
      const formValues = props.isEdit ? pick(luckyRoll, Object.keys(formValue)) : { ...formValue }

      return {
        ...formValues,
        min_bet_price: formatToDecimal(formValues.min_bet_price.amount),
        max_bet_price: formatToDecimal(formValues.max_bet_price.amount)
      }
    }

    const frontToBackMapperOnUpdate = () => {
      const mapper = {
        min_bet_price: formatToCents,
        max_bet_price: formatToCents
      }
      return getDifferingFields(initialValue.value, formValue, mapper)
    }

    const frontToBackMapperOnCreate = () => {
      return {
        ...formValue,
        min_bet_price: formatToCents(formValue.min_bet_price),
        max_bet_price: formatToCents(formValue.max_bet_price)
      }
    }

    const formResource = props.isEdit
      ? passDataToResource(luckyRolls.update, {
        frontToBackMapper: frontToBackMapperOnUpdate,
        requestParams: { urlParams: { id: router.currentRoute.params.id } }
      })
      : passDataToResource(luckyRolls.create, {
        frontToBackMapper: frontToBackMapperOnCreate
      })

    const updateFormValues = () => {
      if (props.isEdit) {
        const formattedValues = backToFrontMapper(props.luckyRoll)
        setValuesToForm(formattedValues, formValue)
        initialValue.value = formattedValues
      }
    }

    watch(() => props.luckyRoll, () => {
      if (props.isEdit) {
        updateFormValues()
      }
    })

    const handleSubmit = async ([err, res]) => {
      if (err) return

      if (!props.isEdit) {
        await router.push(buildRoute(path.luckyRollEdit, { params: { id: res.data.id } }))
      }

      initialValue.value = cloneDeep(formValue)
    }

    return {
      formValue,
      rules,
      canSubmit,
      activityTypes,
      formResource,
      handleSubmit
    }
  }
}
</script>
