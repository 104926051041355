<template>
  <div class="card p-2">
    <lucky-roll-editor
      :lucky-roll="luckyRollValue"
      :is-edit="isEdit"
      :is-prizes-empty="isPrizesEmpty"
    />
    <prizes-editor
      v-if="showPrizesEditor"
      v-model="prizesValue"
      :lucky-roll-id="luckyRollId"
      @refresh="getLuckyRoll"
    />
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from '@vue/composition-api'
import router from '@/router'
import { luckyRolls, useResource } from '@/services/resources'
import { isEmpty } from 'lodash'
import { permissions, useCan } from '@/plugins/acl'
import LuckyRollEditor from '@/views/lucky-rolls/LuckyRollEditor'
import PrizesEditor from '@/views/lucky-rolls/PrizesEditor'

export default {
  name: 'LuckyRollWithPrizeEditor',
  components: { LuckyRollEditor, PrizesEditor },
  setup () {
    const luckyRollRef = ref(null)
    const prizesRef = ref([])

    const luckyRollValue = computed({
      get: () => luckyRollRef.value,
      set: (val) => {
        luckyRollRef.value = val
      }
    })

    const prizesValue = computed({
      get: () => prizesRef.value,
      set: (val) => {
        prizesRef.value = val
      }
    })

    const canView = useCan(permissions.luckyRollPrizeView)

    const luckyRollId = computed(() => router.currentRoute.params.id || null)
    const isEdit = computed(() => !!luckyRollId.value)
    const isPrizesEmpty = computed(() => isEmpty(prizesValue))
    const showPrizesEditor = computed(() => {
      return isEdit.value && luckyRollValue.value && canView
    })

    onBeforeMount(async () => {
      if (isEdit.value) {
        await getLuckyRoll()
      }
    })

    const getLuckyRoll = async () => {
      const { can, callRequest } = useResource(luckyRolls.getOne)

      if (can) {
        const [, res] = await callRequest({
          urlParams: { id: luckyRollId.value },
          params: { include: 'prizes,prizes.entity.file' }
        })
        if (!res) return

        const { prizes = [], ...luckyRoll } = res.data

        prizesValue.value = prizes
        luckyRollValue.value = luckyRoll
      }
    }

    return {
      luckyRollValue,
      prizesValue,
      isPrizesEmpty,
      showPrizesEditor,
      luckyRollId,
      isEdit,
      getLuckyRoll
    }
  }
}
</script>
