<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <p class="font-weight-bolder">
      {{ title }}
    </p>

    <div class="row justify-content-center">
      <b-img
        v-if="image"
        :src="image"
        :alt="title"
        width="320"
      />
    </div>

    <vx-textarea
      v-model="formValue.description"
      :rules="rules.description"
      name="description"
      label="Description"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { VxForm, VxTextarea } from '@/components/form'
import { onBeforeMount, reactive } from '@vue/composition-api'
import { VxButton } from '@/components/buttons'
import { defaultPrizeValues, editorTypes, luckyRolls, passDataToResource } from '@/services/resources'
import { getRouteIdParam } from '@/router'
import { pick } from 'lodash'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'PrizeEditor',
  components: {
    VxForm,
    VxButton,
    VxTextarea,
    BImg
  },
  props: {
    luckyRollId: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      default: null
    },
    entityType: {
      type: String
    }
  },
  setup (props, { emit }) {
    const { entity, entityType } = props
    const actionType = entityType
    const title = entity?.title || entity?.entity?.title
    const image = entity?.entity?.file?.external_link

    const backToFrontMapper = () => {
      formValue.description = ''

      if (actionType === editorTypes.prize) {
        setValuesToForm(
          pick(entity, Object.keys(defaultPrizeValues)),
          formValue
        )
      } else {
        setValuesToForm(
          {
            ...defaultPrizeValues,
            entity_id: entity.id
          },
          formValue
        )
      }
    }

    const frontToBackMapper = () => {
      return { ...formValue }
    }

    const formResource = actionType === editorTypes.prize
      ? passDataToResource(luckyRolls.prizesUpdate, {
        frontToBackMapper,
        requestParams: { urlParams: { id: entity.id } }
      })
      : passDataToResource(luckyRolls.prizeCreate, {
        frontToBackMapper,
        requestParams: { urlParams: { id: getRouteIdParam() } }
      })

    const formValue = reactive(defaultPrizeValues)

    const rules = {
      description: {
        max: 255
      },
      entity_id: {
        required: true
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res.data)
      }
    }

    onBeforeMount(() => {
      backToFrontMapper()
    })

    return {
      formResource,
      formValue,
      rules,
      handleSubmit,

      title,
      image
    }
  }
}
</script>
