<template>
  <div>
    <prizes
      :prize-on-changing-item-mode.sync="prizeOnChangingItemMode"
      :prizes="prizes"
      @onSelect="onSelectPrize"
      @onToggleActive="handleToggleActive"
    />
    <items
      :prize-on-changing-item-mode="prizeOnChangingItemMode"
      :prizes="prizes"
      @onSelect="onSelectItem"
      @onChangeItemInPrize="handleChangeItemInPrize"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <prize-editor
        :lucky-roll-id="luckyRollId"
        :entity-type="selectedEntityType"
        :entity="entity"
        @submit="handleSubmitPrizeForm"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { computed, ref, set, watch } from '@vue/composition-api'
import { editorTypes, defaultPrizeValues, luckyRolls, useResource } from '@/services/resources'
import { findIndex, pick } from 'lodash'
import Prizes from '@/views/lucky-rolls/Prizes'
import Items from '@/views/lucky-rolls/Items'
import PrizeEditor from '@/views/lucky-rolls/PrizeEditor'
import { useModalEditor } from '@/services/modal'

export default {
  name: 'PrizesEditor',
  components: { Prizes, Items, PrizeEditor, BModal },
  props: {
    luckyRollId: {
      type: String
    },
    value: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedEntityType = ref('')
    const prizeOnChangingItemMode = ref(null)
    const prizes = ref(props.value)
    const { modalIsOpen, entity, closeModal } = useModalEditor()
    const modalTitle = computed(() => {
      return (selectedEntityType.value === editorTypes.prize ? 'Update' : 'Create') + ' Prize'
    })

    watch(() => props.value, () => {
      prizes.value = props.value
    })

    const handleToggleActive = async ({ id }) => {
      const { can, callRequest } = useResource(luckyRolls.prizeToggle)

      if (can) {
        const [, res] = await callRequest({ urlParams: { id } })
        if (!res) return

        setAllPrizesDisabledExceptOne(id)
      }
    }

    const onSelectItem = (item) => {
      entity.value = item
      selectedEntityType.value = editorTypes.item
      modalIsOpen.value = true
    }

    const onSelectPrize = (prize) => {
      entity.value = prize
      selectedEntityType.value = editorTypes.prize
      modalIsOpen.value = true
    }

    const getPrize = async (id) => {
      const {
        can: canViewPrize,
        callRequest: getPrizeRequest
      } = useResource(luckyRolls.prize)

      if (canViewPrize) {
        const [, res] = await getPrizeRequest({
          urlParams: { id },
          params: { include: 'entity,entity.file' }
        })

        updatePrize(res.data)
        prizeOnChangingItemMode.value = null
      }
    }

    const handleChangeItemInPrize = async (item) => {
      if (!prizeOnChangingItemMode.value) return null

      const { id: prizeId } = prizeOnChangingItemMode.value
      const payload = {
        ...pick(prizeOnChangingItemMode.value, Object.keys(defaultPrizeValues)),
        entity_id: item.id
      }

      const {
        can: canUpdatePrize,
        callRequest: prizeUpdateRequest
      } = useResource(luckyRolls.prizesUpdate)

      if (canUpdatePrize) {
        await prizeUpdateRequest({ urlParams: { id: prizeId }, data: payload })
      }

      await getPrize(prizeId)
    }

    const handleSubmitPrizeForm = (prize) => {
      const afterSubmitActions = {
        [editorTypes.prize]: () => getPrize(prize.id),
        [editorTypes.item]: () => emit('refresh')
      }
      afterSubmitActions[selectedEntityType.value]()

      selectedEntityType.value = null
      entity.value = prize
      modalIsOpen.value = false
    }

    const setAllPrizesDisabledExceptOne = (id) => {
      prizes.value.forEach(({ id: prizeId }, i) => {
        prizes.value[i].is_enabled = prizeId === id
      })
    }

    const updatePrize = (prize) => {
      const indexOfPrize = findIndex(prizes.value, ['id', prize.id])
      set(prizes.value, indexOfPrize, prize)
    }

    return {
      prizeOnChangingItemMode,
      prizes,
      onSelectPrize,
      handleToggleActive,

      onSelectItem,
      handleChangeItemInPrize,

      luckyRollIdentifier: props.luckyRollId,
      entity,
      selectedEntityType,
      handleSubmitPrizeForm,

      modalTitle,
      modalIsOpen,
      closeModal
    }
  }
}
</script>
